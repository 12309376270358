import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button, Layout, Text } from '@shopify/polaris';
import { fetchPlans, setPlan,fetchProducts } from '../services/shopifyService';
import { getCurrentPlan,setCancelPlan } from "../services/settingService";
import Check from './../img/check.png';
import Swal from 'sweetalert2';
import "../PlanView.css";

const PlanViewPage = () => {
  const [plans, setPlans] = useState([]);
	 const [freePlanEnable, setFreePlanEnable] = useState(false);
	const [currentplan, setCurrentPlan] = useState([]);
	const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
	  
	  
	const fetchProductsFromApi = async () => {
      try {
		  
		  	 const urlParams2 = new URLSearchParams(location.search);
   		 const shopurl = urlParams2.get('shop');
        const response = await fetchProducts(shopurl, "");
		  
		  const products = response.products;
		     console.log("Product Count:", products.length);
		  setFreePlanEnable(products.length > 4 ? false : true);
        console.log("Data retrieved successfully:", response.products);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };


    const fetchPlan = async () => {
      try {
        const response = await fetchPlans();
        setPlans(response);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

	  
	 const fetchData = async () => {
      try {
		 const urlParams = new URLSearchParams(location.search);
   		 const shop = urlParams.get('shop');

        const response = await getCurrentPlan(shop);
        if (response.data && response.data) {
          setCurrentPlan(response.data.data);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
       
      }
    };
    fetchPlan();
	  fetchData();
	  fetchProductsFromApi();
  }, []);

  const handleSubscribe = async (planId) => {
    const urlParams = new URLSearchParams(location.search);
    const shop = urlParams.get('shop');

    const response = await setPlan(planId, shop);

    if (response && response.data && response.data.confirmationUrl) {

      window.top.location.href = response.data.confirmationUrl;
    }
	  
	 else   if (response && response.data && response.data.freePlan) {

      handleSetting();
    }
  };
	
	  const handleSetting = async () => {
		  
		const urlParams = new URLSearchParams(location.search);
   		 const shop = urlParams.get('shop');
			navigate(`/shopify/app_view?shop=${shop}`);
		};
	
	 const cancelPlan = async () => {
		 
		 
		  Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel this current plan ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
     }).then(async (result) => {
      if (result.isConfirmed) {
         
		  try {
			  		const urlParams = new URLSearchParams(location.search);
   		 const shop = urlParams.get('shop');
        const response = await setCancelPlan(shop);
        if (response.data && response.data) {
          navigate(`/shopify/app_view?shop=${shop}&upgrade=1`);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
       
      }
        
      }
    });
		 
		 
		 
		 
	};

  return (
    <Layout>
	  	  {currentplan && (<button  style={{  borderRadius: "0.5rem", padding:"10px", marginTop:"20px" }} onClick={handleSetting}>Back to Setting</button>)}

      <div className='grid-container'  style={{  width:"100%" }}>
		
        {plans.map((plan) => {
          // Parse plan descriptions from JSON string
          const descriptions = JSON.parse(plan.plan_descriptions);

          console.log(typeof plan.plan_price === 0.00 ? 'inline' : 'flex',)
          return (
            <div className='grid-item' style={{ display: plan.plan_price == 0.00 ? 'inline' : 'flex', }}>
              <Layout.Section oneThird key={plan.id} >
                <div style={{ display: plan.plan_price === 0.00 ? 'inline' : 'flex',height:"100%" }}>
                  <div className='cardTemplate justify-between box c' style={{
                    display: plan.plan_price == 0.00 ? 'flex' : 'block',
                    width: '100%',
                    margin: "10px",
                    borderRadius: "0.25rem",
                    borderWidth: "1px",
                    backgroundColor: "#fff",
                    padding: "3.5rem",
                    boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)"
                  }}>
                    <div className={`ribbon ribbon-top-right ${plan.id == "2" ? '' : 'ribbonNone'}`}><span>POPULAR</span></div>
                    <div className="card-section" style={{ width: plan.plan_price === 0.00 ? "150px" : "" }}>
                      <Text variant="headingLg" as="h2">{plan.plan_name}</Text>
                    </div>
                    <div className="card-section">
                      <ul>
                        {/* Render each description */}
                        {Object.entries(descriptions).map(([key, desc], index) => (
                          <li style={{ listStyle: "none", color: "#64748b" }} key={index}> <img src={Check} /> {desc}</li>
                        ))}
                      </ul>
                    </div>
                    {plan.plan_price != 0.00 && (
                      <div className="card-section">
                        <Text variant="headingLg" as="h3">${plan.plan_price}</Text>
                      </div>
                    )}
                    <div className="card-section">
                      <button className="primary" 
					  
					  disabled={((currentplan && currentplan.id && currentplan.id == plan.id) || (!freePlanEnable && plan.id == 1))}
					  style={{
                        width: "15rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "#047857",
                        padding: "0.5rem",
                        color: "#ffff",
						marginTop: "20px"

                      }} onClick={() => handleSubscribe(plan.id)}> Start 7-day trial </button>
					  { (!freePlanEnable && plan.id == 1) && (<div>You cannot Select Free Plan. As you have more than 4 Products</div>)}
					  
					            {currentplan && currentplan.id && currentplan.id == plan.id && (<div><br/><button  
								  style={{
                        width: "15rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "#900",
                        padding: "0.5rem",
                        color: "#ffff",
						
                      }}
								onClick={cancelPlan}>Cancel Plan</button><br/> Currently Active. </div>)}

                    </div>
                  </div>
                </div>
              </Layout.Section>
            </div>
          );
        })}
      </div>
    </Layout >
  );
};

export default PlanViewPage;
