// InstallPage.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkPlanAndRedirect, fetchProducts } from '../services/shopifyService';
import Loader from '../img/spinner.svg'

const AppViewPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        // Extract query parameters from the URL
        const urlParams = new URLSearchParams(location.search);
        const shop = urlParams.get('shop');
		const currentParams = window.location.search;

        if (shop) {
            checkPlanAndRedirect(shop, navigate,currentParams);
        } else {
            // Handle case where shop parameter is missing
            console.log('Shop parameter is missing in the URL');
        }
    }, [location.search]);
	
	
	useEffect(() => {
    const fetchData = async () => {
		
		  const urlParams = new URLSearchParams(location.search);
        const shop = urlParams.get('shop');
		const currentParams = window.location.search;
      try {
        const response = await fetchProducts(shop, "");
       const products = response.products;
		       if (shop) {
            checkPlanAndRedirect(shop, navigate,currentParams, products.length);
        } else {
            // Handle case where shop parameter is missing
            console.log('Shop parameter is missing in the URL');
        }
		     
        console.log("Data retrieved successfully:", response.products);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };

    fetchData();
  }, [location.search]);


    return <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}><img style={{margin:'auto', marginTop: '20%'}} src={Loader} /></div>;
};

export default AppViewPage;
